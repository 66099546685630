<template>
  <div class="front_container">
    <!-- 头部背景部分 -->
    <div class="front_top">
      <div class="title">减分识题</div>
      <div class="advantage">简单易用、多任务、应用同时操作、更轻松的完成日常试题学习</div>
      <div class="details">
        <div>
          提升反应速度:高频次、快速答题训练，帮助用户在考试中更快做出正确选择。高效记忆:通过快速练习和实时反馈，用户能够更高效地记忆和巩固知识点。
        </div>
        <div>
          全面覆盖:随机出题确保练习覆盖所有知识点，避免遗漏任何重要内容。数据分析:详细的练习统计和错题记录，帮助用户针对性提高，逐步减少错误。
        </div>
      </div>
    </div>
    <!-- 中间介绍部分 -->
    <div class="front_main">
      <!-- 产品介绍 -->
      <div class="introduce">
        <div class="title">产品介绍</div>
        <el-row type="flex" justify="center" align="center">
          <el-col :span="15">
            <div class="introduce_text">
              <div><span style="color:#1684fc">“浮窗”功能是《减分识题》应用中的一项重要创新，通过高效、快速的练习模式，帮助用户在短时间内提升学习效果和考试通过率，轻松备考驾驶证考试。</span></div>
            </div>
          </el-col>
          <el-col :span="9">
            <div class="pic">
              <img src="https://ledong-1305301454.cos.ap-guangzhou.myqcloud.com/%E5%87%8F%E5%88%86%E8%AF%86%E9%A2%98%E5%AE%98%E7%BD%91%EF%BC%88%E8%83%8C%E6%99%AF%EF%BC%8C%E4%B8%8A%E4%BC%A0%EF%BC%89%28%E5%8E%8B%E7%BC%A9%EF%BC%89.jpg" alt="">
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 核心功能 -->
      <div class="main_function">
        <div class="title">核心功能</div>
        <div class="language">FUNCTION</div>
        <el-row type="flex" justify="center" align="center">
          <el-col :span="12" style="display: flex;justify-content: center;">
            <div class="pic">
              <img src="https://ledong-1305301454.cos.ap-guangzhou.myqcloud.com/%E5%88%86%E4%BA%AB%E6%B5%B7%E6%8A%A5%E9%85%8D%E5%9B%BE.001.jpeg" alt="">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="function_text_box">
              <!-- 学员管理 -->
              <div class="function_item">
                <div class="title">
                  浮窗读屏
                </div>
                <div class="details_text">应用的浮窗操作功能为用户提供了极大的便利，帮助用户在不同的应用和界面之间 进行高效的多任务处理。</div>
              </div>
              <!-- 财务管理 -->
              <div class="function_item">
                <div class="title">
                  精准题库
                </div>
                <div class="details_text">高效备考:通过精准题库和详细解析，用户能够高效掌握考试内容，提升考试通过率个性化学习:智能推荐和错题管理功能，帮助用户针对性复习，提升学习效果。
                  实时更新:确保题库内容与官方同步更新，用户练习的题目始终保持最新。</div>
              </div>
            </div>

          </el-col>
        </el-row>
      </div>
      <!-- 产品价值 -->
      <div class="product_value">
        <el-row type="flex" justify="center" align="center" style="margin-bottom:50px">
          <el-col :span="10">
            <div class="left">
              <div class="value_pic">
                <img :src="value1_pic" alt="">
              </div>
              <div class="title">让用户，能轻松完成更多的学习内容</div>
              <div class="text">能最大提高用户的学习效率</div>
            </div>

          </el-col>
          <el-col :span="14">
            <div class="right">
              <div class="value_pic">
                <img :src="value11_pic" alt="">
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

    </div>
    <!-- 底部 -->
    <div class="front_footer">
      <BottomFooter></BottomFooter>
    </div>

  </div>
</template>

<script>
  import BottomFooter from '../components/BottomFooter'
  export default {
    name: 'WorkspaceJsonFrontpc',
    components: {
      BottomFooter
    },

    data() {
      return {
        introduce_text: 'LD前台，为驾校用户提供四大驾培行业刚需功能、学员管理、财务管理、考试管理、学员后台，同时根据驾校的主流运营方式以及人员架构提供多职位的权限分类、结合当前驾培行业的硬件定位及从业人员等特点，以更易上手的www.网页形式作为系统载体、根据行业的信息类别需求，提供便捷的模版数据导入功能。',
        introduce_pic: require('.././assets/introduce.png'),
        function_pic: require('.././assets/function.png'),
        value1_pic: require('.././assets/value1.png'),
        value11_pic: require('.././assets/value1-1.png'),
        value2_pic: require('.././assets/value2.png'),
        value22_pic: require('.././assets/value2-2.png'),
        value3_pic: require('.././assets/value3.png'),
        value33_pic: require('.././assets/value3-3.png'),

      };
    },

    mounted() {

    },

    methods: {

    },
  };

</script>

<style lang="scss" scoped>
  .front_container {
    width: 100%;
    height: 100%;

    .front_top {
      width: 100%;
      height: 300px;
      background: url(".././assets/front_bgc.png") no-repeat center center / auto 100% rgb(245, 245, 245);;
      background-size: 100% 100%;
      color: #ffffff;
      box-sizing: border-box;
      padding: 50px 0;

      .title {
        font-size: 40px;
        margin-bottom: 20px;
      }

      .advantage {
        font-size: 26px;
        margin-bottom: 20px;
      }

      .details {
        width: 80%;
        margin: 0 auto;
        font-size: 15px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .front_main {
      box-sizing: border-box;
      padding: 30px 40px;
      border-bottom: 1px solid #ccc;

      .introduce,
      .main_function,
      .product_value {
        width: 100%;

        .title {
          font-size: 25px;
          font-weight: 600;
          margin-bottom: 20px;
        }

        .language {
          color: rgba(233, 233, 233, 1);
          margin-bottom: 20px;
        }

        .introduce_text {
          width: 80%;
          height: 100%;
          text-align: left;
          line-height: 40px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          padding: 0 40px;
        }

        .pic {
          width: 250px;
          height: 500px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .function_text_box {
          text-align: left;
          width: 600px;

          .function_item {
            margin-bottom: 30px;

            .title {
              font-size: 18px;
              font-weight: normal;
              margin-bottom: 10px;
            }

            .details_text {
              line-height: 30px;
              color: rgba(145, 145, 145, 1);
            }
          }

        }

        .left {
          width: 100%;
          box-sizing: border-box;
          padding-left: 60px;
          text-align: left;

          .value_pic {
            width: 60px;
            height: 50px;
            margin-bottom: 20px;
          }

          .title {
            font-size: 18px;
            font-weight: 600;
          }

          .text {
            color: rgba(145, 145, 145, 1);
          }
        }

        .right {
          width: 600px;
          height: 140px;
          display: flex;
          align-items: center;
          position: relative;

          .text_list {
            text-align: left;
            margin-left: 40px;
          }

          .value_pic {
            width: 150px;
            height: 150px;
            position: absolute;
            right: -60px;
            bottom: 50px;
          }
        }


      }
    }

    .front_footer {
      height: 100px !important;
    }

  }

</style>
